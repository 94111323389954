var globals = require('./globals');
var config = require('./config');

module.exports = function loadBlueScript(version) {
  if (!version) {
    return undefined;
  }

  var href = config.scriptSource.replace('${version}', version);

  // Add preload link to increase script priority from 'Low' to 'High'.
  // See https://developer.mozilla.org/en-US/docs/Web/HTML/Preloading_content#Scripting_and_preloads
  var preloadLink = globals.document.createElement('link');
  preloadLink.href = href;
  preloadLink.rel = 'preload';
  preloadLink.as = 'script';
  globals.document.head.appendChild(preloadLink);

  var blueScript = globals.document.createElement('script');
  blueScript.src = href;
  globals.document.head.appendChild(blueScript);
  return blueScript;
};

module.exports = function hasMatch(source, match) {
  if (!source || !match) {
    return false;
  }

  // Check match.constructor instead of match.constructor.name since
  // older browsers don't support constructor names.
  if (match.constructor === String) {
    return source.indexOf(match) >= 0;
  }
  if (match.constructor === RegExp) {
    return match.test(source);
  }

  if (match.constructor === Array) {
    for (var i = 0; i < match.length; i++) {
      var m = match[i];
      if (hasMatch(source, m)) {
        return true;
      }
    }
  }

  return false;
};

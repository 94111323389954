var config = require('./config');
var getWindowValue = require('./getWindowValue');
var hasMatch = require('./hasMatch');
var loadBlueScript = require('./loadBlueScript');

module.exports = function loadMatchingVersion() {
  var rules = config.rules;
  var defaults = config.defaults;
  var defaultRule;
  for (var i = 0; i < rules.length; i++) {
    var rule = rules[i];
    if (rule.default) {
      defaultRule = rule;
    }
    var varName = rule.varName || defaults.varName;
    var varValue = getWindowValue(varName);
    if (hasMatch(varValue, rule.match)) {
      return loadBlueScript(rule.version);
    }
  }

  // Fallback to default rule
  return loadBlueScript(defaultRule.version);
};

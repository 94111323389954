var globals = require('./globals');

module.exports = function setWindowValue(path, value) {
  var object = globals.window;
  if (!path) {
    return object;
  }
  
  var pathParts = path.split('.').filter(function(part){
    return part.length > 0;
  });

  var traversedObject = object;
  for (var i = 0; i < pathParts.length; i++) {
    var property = pathParts[i];
    var isLastProperty = (i === pathParts.length - 1);
    if (isLastProperty) {
      traversedObject[property] = value;
    } else {
      if (!(property in traversedObject)) {
        traversedObject[property] = {};
      }
    }
    traversedObject = traversedObject[property];
  }
  return object;
};
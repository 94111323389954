var globals = require('./globals');

/**
 * Redirects unsupported browsers to an explanatory page relative to the registry host.
 */
module.exports = function redirectUnsupported(registryHost) {
  if (!isSupportedBrowser()) {
    globals.window.location.href = registryHost + '/start/unsupported.html';
  }
};

// See https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
function isSupportedBrowser() {
  var ua = globals.window.navigator.userAgent;

  // Chrome/Chromium quick return (necessary because Chrome UA includes "Safari")
  if (ua.indexOf('Chrom') >= 0) {
    return true;
  }

  // IE10 or older
  if (ua.indexOf('MSIE') >= 0) {
    return false;
  }

  // IE11
  if (ua.indexOf('Trident/') >= 0) {
    return false;
  }

  if (ua.indexOf('Safari/') >= 0) {
    var safariMatch = ua.match(/Version\/([\d]+)/);
    if (safariMatch !== null) {
      var safariVersion = safariMatch[1];
      if (safariVersion < 10) {
        return false;
      }
    }
  }

  // Blacklisting unsupported browsers. Allow otherwise.
  return true;
}

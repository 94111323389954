var globals = require('./globals');

module.exports = function getWindowValue(path) {
  if (!path) {
    return undefined;
  }

  var object = globals.window;
  // Split on property or array delimiters and strip out any strings
  // returned by split.
  var pathParts = path.split(/[.\[\]]/).filter(function(part) {
    return part.length > 0;
  });

  for (var i = 0; i < pathParts.length; i++) {
    var property = pathParts[i];
    if (!(property in object)) {
      return undefined;
    }
    object = object[property];
  }
  return object;
};
var config = require('./config');
var getWindowValue = require('./getWindowValue');
var setWindowValue = require('./setWindowValue');

/**
 * Sets the blue registry host global for externally-hosted apps.
 * If the global is already set at `window.printos.blue.ui.registry.host`,
 * not action will be taken. If a subdomain is detected and matches
 * against a configured `primaryDomain`, the primary domain will be used as the
 * registry host.
 */
module.exports = function setBlueRegistryHost() {
  var globalConfigPath = 'printos.blue.ui.registry.host';

  var configuredHost = getWindowValue(globalConfigPath);
  if (configuredHost) {
    // registry host already configured, so do nothing.
    return configuredHost;
  }

  var hostParts = getWindowValue('location.hostname').split('.');
  if (hostParts.length > 3) {
    var domain = hostParts.slice(-2).join('.');
    if (config.primaryDomains.indexOf(domain) >= 0) {
      var host = 'https://' + hostParts.slice(-3).join('.');
      setWindowValue(globalConfigPath, host);
      return host;
    }

  }
  return '';
};
